l
<template>
  <section class="pay-rules-wrapper" v-loading="loading">
    <div style="padding: 20px; color: #666; background: #fff; margin-bottom: 10px">
      供应商ID：{{ $route.query.storeId }}
      <span style="margin-left: 20px">供应商名称：{{ $route.query.storeName }}</span>
    </div>
    <div class="header">
      <el-tabs v-model="type" tab-position="left">
        <el-tab-pane label="收款订单分账规则配置" name="1">
          <div class="pay-rules-content">
            <pay-form v-if="type == 1" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="加购订单分账规则配置" name="2">
          <div class="pay-rules-content">
            <additional-form v-if="type == 2" />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </section>
</template>

<script>
import PayForm from './PayForm.vue';
import AdditionalForm from './AdditionalForm.vue';
// import { storeproportion } from '@/api/store';

export default {
  name: 'PayRule',
  components: {
    // CommonTitle
    PayForm,
    AdditionalForm,
  },
  data() {
    return {
      type: '1',
      loading: false,
    };
  },
  created() {},
  methods: {},
};
</script>

<style scoped lang="less">
.pay-rules-wrapper {
  margin-top: 16px;
  .pay-rules-content {
    background: #fff;
    display: flex;
    justify-content: center;
    padding: 30px;
  }
  .header {
    background: #fff;
  }
}
</style>
