<template>
  <el-form v-loading="loading" label-width="160px" class="form" :model="addForm" :rules="{}">
    <el-form-item label="平台分账比例：" prop="number">
      <el-input-number :controls="false" :precision="2" :min="0.0" :max="100" v-model="value"></el-input-number>
      %
    </el-form-item>
    <el-form-item label="服务供应商分账比例：">
      <el-input placeholder="请输入" disabled v-model="storeNumber"></el-input>
      %
    </el-form-item>
    <div class="tip">注：以上为加购订单支付金额分账比例，加购订单完成后，系统将按照以上比例分账 若不设置，则使用通用分账比例进行分账</div>
    <el-form-item label="">
      <el-button @click="$router.back()">返回</el-button>
      <el-button @click="setConfig" type="primary">确认</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { storeproportion, storeproportionSave } from '@/api/store';

export default {
  name: 'AdditionalForm',
  data() {
    return {
      value: 0,
      storeNumber: 0,
      addForm: {},
      loading: false,
    };
  },
  watch: {
    value(val) {
      console.log(val);
      this.storeNumber = (100 - (this.value || this.value == 0 ? this.value : 100)).toFixed(2);
    },
    storeNumber() {
      this.value = 100 - (this.storeNumber || this.storeNumber == 0 ? this.storeNumber : 100);
    },
  },
  mounted() {
    this.getKeys();
  },
  methods: {
    async getKeys() {
      this.loading = true;
      const params = {
        data: {
          storeId: this.$route.query.storeId,
          isAddition: 1,
        },
      };
      storeproportion(params).then((res) => {
        this.loading = false;
        this.storeNumber = res.data.splitProportion;
        this.value = 100 - (this.storeNumber || this.storeNumber == 0 ? this.storeNumber : 100);
      });
    },
    setConfig() {
      storeproportionSave({
        data: {
          storeId: this.$route.query.storeId,
          splitProportion: this.storeNumber,
          isAddition: 1,
        },
      }).then(() => {
        this.$message({
          message: '操作成功',
          type: 'success',
        });
        this.getKeys();
      });
    },
  },
};
</script>

<style scoped lang="less">
.form {
  width: 600px;
  .tip {
    font-size: 12px;
    padding: 30px;
    color: #e77453;
    line-height: 20px;
  }
  ::v-deep.el-input-number {
    width: 100%;
    .el-input__inner {
      text-align: left;
    }
  }
  ::v-deep .el-form-item__content {
    display: flex;
    .el-input,
    .el-input-number {
      margin-right: 8px;
    }
  }
}
</style>
